/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	template: {},
});

export const mutations = {
	SET_TEMPLATE (state, template) {
		state.template = template;
	},
};

export const actions = {
	fetchTemplate ({ commit }, template) {
		commit('SET_TEMPLATE', template);
	},
};
