/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	signatures: {
		consumerSignature: '',
	},
});

export const mutations = {
	SET_SIGNATURE (state, signatures) {
		state.signatures = signatures;
	},
};

export const actions = {
	fetchSignature ({ commit }, signature) {
		commit('SET_SIGNATURE', signature);
	},
};
