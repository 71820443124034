export default function ({ $axios, $auth }) {

	$axios.onError(err => {
		if (err.response && err.response.code === 401) {
			$auth.logout();
			window.close();
			// return Promise.resolve(err);
		}
		return Promise.reject(err);
	});
}
