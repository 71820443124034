/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	customer: {
		customer: {
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			phonePrefix: '+421',
			dateOfBirth: '',
			gender: '',
			customerId: '',
			contract: null,
			contractSigned: null,
			retailerName: '',
			street: '',
			streetNumber: '',
			city: '',
			zip: '',
			product: '',
			delivery: '',

		},
		retailer: {
			name: '',
			email: '',
			jwt: '',
		},
	},

	token: null,

	identifier: '',
});

export const mutations = {
	SET_CUSTOMER (state, customer) {
		state.customer = customer;
	},

	SET_TOKEN (state, token) {
		state.token = token;
	},

	SET_IDENTIFIER (state, identifier) {
		state.identifier = identifier;
	},
};

export const actions = {
	fetchCustomer ({ commit }, customer) {
		customer.forEach;
		commit('SET_CUSTOMER', customer);
	},

	modifyCustomer ({ commit }, customer) {
		commit('SET_CUSTOMER', customer);
	},

	updateField ({ commit }, { fieldName, updatedValue }) {
		commit('UPDATE_FIELD', { fieldName, updatedValue });
	},


	fetchToken ({ commit }, token) {
		commit('SET_TOKEN', token);
	},

	fetchIdentifier ({ commit }, identifier) {
		commit('SET_IDENTIFIER', identifier);
	},
};
