/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

export const state = () => ({
	identifier: {},
});

export const mutations = {
	SET_IDENTIFIER (state, identifier) {
		state.identifier = identifier;
	},
};

export const actions = {
	fetchIdentifier ({ commit }, identifier) {
		commit('SET_IDENTIFIER', identifier);
	},
};
