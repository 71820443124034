
export default {
	computed: {
		targetOrigin () {
			if (this.$i18n.locale === 'sk') {
				return 'https://sk.pmiopen.com';
			}
			return 'https://cz.pmiopen.com';

		},
	},

	watch: {
		$route () {
			window.parent.postMessage({ task: 'scrollTop' }, this.targetOrigin);
		},
	},
};
